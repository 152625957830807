.header480 {
  display: flex;
  width: 100%;
  height: 3.6875rem;
  align-items: center;
  margin-top: 2rem;
}

.header480__menu {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  margin-left: 2rem;
}

.header__logo {
  /* width: 9.4375rem; */
  height: 2.3125rem;
  margin-left: 0.44rem;
}

a {
  display: flex;
  align-items: center;
}

a:focus {
  outline: none !important; /* 포커스 아웃라인 제거 */
}
