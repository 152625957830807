.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.footer__link {
  color: var(--frame-text, #838383);
  font-family: "Pretendard Variable";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.footer__line {
  width: 0.05rem;
  height: 0.9375rem;
  background: #e6e6e6;
  margin-left: 1.62rem;
  margin-right: 1.62rem;
}

@media (max-width: 480px) {
  .footer {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .footer {
    height: 9.3125rem;
    font-size: 0.75rem;
  }
}

@media (min-width: 768px) {
  .footer {
    height: 11.9375rem;
    font-size: 1rem;
  }
}
