.mypage-main-wrapper {
  width: 100%;
  font-family: "Pretendard Variable";
  white-space: nowrap;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mypage-main {
  margin-bottom: 2.5rem;
}
.mypage-main-content-box {
  color: var(--title, #2e2d2d);
  font-weight: 600;
}
.mypage-main-content-box-children {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mypage-main-content-box-text {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  color: var(--frame-text, #838383);
  font-weight: 500;
  border-radius: 0.3125rem;
  border: 0.0625rem solid var(--frame-text, #838383);
  padding: 0 1.2rem;
  width: 100%;
}
.mypage-main-content-box-text span {
  color: var(--title, #2e2d2d);
  font-weight: 600;
}
.mypage-logout__wrapper {
  color: var(--frame-text, #838383);
  text-align: center;
  font-weight: 600;
  margin-top: 7.5rem;
  /* footer와의 사이값을 디자인 나오면 수정 */
}

@media screen and (min-width: 768px) {
  /* 확인하여 처리한 부분 */
  .mypage-main-wrapper {
    width: 43.1rem;
    margin-top: 2.75rem;
  }
  .mypage-main {
    width: 43.1rem;
  }
  .mypage-main-content-box-text {
    font-size: 1.25rem;
    height: 4rem;
    padding: 0 1.63rem;
  }
  .mypage-main-content-box {
    font-size: 1.5rem;
  }
  .mypage-main-content-box-text span {
    font-size: 1rem;
    width: 4.2rem;
    margin-right: 1.44rem;
  }
  .mypage-logout__wrapper {
    font-size: 1.25rem;
  }
}
/* w:768, h:1024일 때 */
@media screen and (min-width: 480px) and (max-width: 768px) {
  /* 확인하여 처리한 부분 */
  .mypage-main-wrapper {
    width: 32.36032rem;
    margin-top: 2.75rem;
  }
  .mypage-main {
    width: 32.36032rem;
  }
  .mypage-main-content-box-text {
    font-size: 1rem;
    height: 3.125rem;
    padding: 0 1.19rem;
  }
  .mypage-main-content-box {
    font-size: 1.25rem;
  }
  .mypage-main-content-box-text span {
    font-size: 1rem;
    width: 3.4rem;
    margin-right: 1.25rem;
  }
  .mypage-logout__wrapper {
    font-size: 1.25rem;
  }
}
/* w:480, h:800일 때 */
@media screen and (max-width: 480px) {
  /* 처리한 부분 */
  /* 모바일의 경우 %로 */
  .mypage-main-wrapper {
    min-width: 27rem;
    margin-top: 3.62rem;
  }
  .mypage-main {
    width: 100%;
    min-width: 27rem;
  }
  .mypage-main-content-box-text {
    font-size: 1rem;
    height: 3.125rem;
    padding: 0 1.19rem;
  }
  .mypage-main-content-box {
    font-size: 1.25rem;
  }
  .mypage-main-content-box-text span {
    font-size: 1rem;
    width: 3.4rem;
    margin-right: 0.75rem;
  }
  .mypage-logout__wrapper {
    font-size: 1rem;
  }
}
