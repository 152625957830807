.red-button {
  display: flex;
  border-radius: 0.3125rem;
  background-color: #d82356;
  border: none;
  color: rgb(255, 255, 255);
  font-family: "Pretendard Variable";
  font-size: 1.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 0 0.8rem 0 0.8rem;
  cursor: pointer;
}

/* w:768, h:1024일 때 */
@media screen and (min-width: 480px) {
  .red-button {
    width: 21.25rem;
    height: 3.125rem;
  }
}

/* w:480, h:800일 때 */
@media screen and (max-width: 480px) {
  .red-button {
    width: 18.75rem;
    height: 3.125rem;
    font-size: 1.25rem;
  }
}
