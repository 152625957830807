/* UserInputPassword */
.user-input-phone-number-content-number-wrapper {
  display: flex;
  flex-direction: column;
  font-family: "Pretendard Variable";
}

.user-input-phone-number-content-number-label-style {
  color: #2e2d2d;
  font-weight: 600;
}

.user-input-phone-number-wrapper {
  display: flex;
  align-items: center;
  font-family: "Pretendard Variable";
}

.user-input-phone-number-input {
  border-radius: 0.3125rem;
  border: 0.05rem solid rgba(131, 131, 131, 1);
  padding: 0.8rem 1rem 0.7rem 1rem;
  font-size: 1rem;
}

.user-input-phone-number-button {
  border-radius: 0.3125rem;
  border: 0.05rem solid rgba(131, 131, 131, 1);
  background-color: white;
  font-weight: 500;
  font-size: 1rem;
  color: var(--frame-text, #838383);
  cursor: pointer;
}

.user-input-phone-number-button-clicked {
  background-color: #ffeaef;
}

.user-input-phone-number-auth-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
}
/* w:768, h:1024 이상일 때 */
@media screen and (min-width: 480px) {
  .user-input-phone-number-content-number-wrapper {
    height: 28.5rem;
  }
  .user-input-phone-number-wrapper {
    width: 28rem;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
  }
  .user-input-phone-number-input {
    width: 21.9375rem;
    height: 3.125rem;
    margin-right: 0.69rem;
    font-size: 1rem;
  }
  .user-input-phone-number-button {
    width: 5.375rem;
    height: 3.125rem;
    font-size: 1rem;
  }
  .user-input-phone-number-auth-button {
    margin-bottom: 7.69rem;
  }
}

/* w:480, h:800일 때 */
@media screen and (max-width: 480px) {
  .user-input-phone-number-content-number-wrapper {
    height: 23rem;
  }
  .user-input-phone-number-wrapper {
    width: 18.75rem;
    margin: 0.25rem 0;
  }
  .user-input-phone-number-input {
    width: 14.0625rem;
    height: 3.125rem;
    margin-right: 0.31rem;
  }
  .user-input-phone-number-button {
    width: 4.375rem;
    height: 3.125rem;
  }
  .user-input-phone-number-auth-button {
    margin-bottom: 5rem;
  }
}
