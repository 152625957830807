.Box {
  width: 97%;
  height: 100%;
  padding: 3vh 0;
}

.rounded-rectangle {
  width: 100%;
  height: 100%;
  padding: 2rem 1rem ;

  background-color: #f5f5f5;
  border-radius: 1.875rem;

  text-align: center;
  color: #000;
  font-size: 0.9px;

  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);

  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.rounded-rectangle::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 768px) {
  .rounded-rectangle {
    padding: 4rem 1rem ;
  }
}

@media screen and (max-width: 480px) {
  .Box {
    width: 100%;
    height: calc(100vh - 6.5rem);
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
  }
  .Box.nonDisplay {
    display: none;
  }
  .rounded-rectangle {
    border-radius: 0;
    box-shadow: none;
    padding: 4rem 1rem ;
  }
}

.receiptWrapper {
  width: 100%;
  height: 100%;
}

.receiptHeader {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}

.receipt-header {
  color: #000;
  font-family: "Pretendard Variable";
  font-weight: 800;
  font-size: 1.75rem;
  letter-spacing: -0.0175rem;
  white-space: nowrap;

  display: flex;
  align-items: center;
  justify-content: center;
}

.receiptTextBox {
  display: flex;
  justify-content: space-between;

  padding: 3% 7% 1% 7%;
}

@media screen and (max-width: 768px) {
  .receiptTextBox {
    padding: 5% 7% 3% 7%;
  }
}

.receipt-FoodName {
  flex-direction: column;
  align-items: flex-start;

  display: block;
  text-align: left;
  color: #4f4f4f;
  font-family: "Pretendard Variable";
  font-weight: 800;
  font-size: 1.4375rem;
  font-weight: 700;
  line-height: 130%; /* 1.625rem */
  letter-spacing: -0.0125rem;
}

.receipt-FoodName.count {
  color: #d82356;
  font-family: "Pretendard Variable";
  font-weight: 800;
  font-size: 1.75rem;
}

.receiptOption {
  padding-left: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.receipt-optiontext {
  color: #4f4f4f;
  font-family: "Pretendard Variable";
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0.5rem 0;
}

.receipt-text {
  color: #4f4f4f;
  font-family: "Pretendard Variable";
  font-weight: 300;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 130%; /* 1.625rem */
  letter-spacing: -0.0125rem;
  white-space: nowrap;
}

.receipt-text.bold {
  font-size: 1.375rem;
  font-weight: 800;
}

.receipt-text.red {
  color: #d82356;
}

.receipt-divider {
  width: 90%;
  height: 1px;
  margin: 1.5rem auto;
  background-color: #838383;
}

.receipt-btn {
  width: 8.75rem;
  height: 2.8125rem;
  border: 2px solid;
  border-radius: 1.5625rem;
  background-color: #d82356;
  font-family: "Pretendard Variable";
  font-weight: 600;
  font-size: 1.375rem;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.receipt-btn-pickUp {
  width: 8.75rem;
  height: 2.8125rem;
  border: 2px solid;
  border-radius: 1.5625rem;
  background-color: #838383;
  font-family: "Pretendard Variable";
  font-weight: 600;
  font-size: 1.375rem;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* 모달 */
.modal-wrapper {
  position: absolute;
  top: 3vh;
  left: 0;

  /* min-width: 22.125rem;
  height: 41.25rem; */
  width: 100%;
  height: calc(100% - 6vh);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 1.875rem;

  display: flex;
}

@media screen and (max-width: 480px) {
  .modal-wrapper {
    /* display: none; */
    width: 100%;
    height: calc(100vh - 6.5rem);
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
  }
}

.modal-box {
  position: absolute;
  /* top: 10.45rem; */
  top: 30%;

  width: 100%;
  height: 70%;
  background-color: #fff;
  border-radius: 1.875rem;
  z-index: 101;
  padding: 2.0625rem 3.25rem;
  overflow-y: auto;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}

.modal-close__wrapper {
  display: flex;
  justify-content: flex-end;
}

.modal-box-txt__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20%;
}

.modal-box-txt {
  color: #4f4f4f;
  font-family: "Pretendard Variable";
  font-weight: 700;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 130%; /* 1.625rem */
  letter-spacing: -0.0125rem;
}

.modal-box-choose-btn__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 3rem;
  width: 100%;
  /* height: 12.9375rem; */
}

.modal-box-choose-btn__row {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  margin: 0 auto;
}

.modal-box-choose-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;

  /* width: 16.8125rem; */
  width: 80%;
  height: 4rem;
  margin: 0 auto;
  border-radius: 0.625rem;
  border: 2px solid #dadada;

  color: #838383;
  text-align: center;
  font-family: "Pretendard Variable";
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 100%; /* 1.375rem */
}

.modal-box-choose-btn:active {
  background-color: #d82356;
  border: 2px solid #d82356;
  color: #fff;
}

.modal-box-chooseTime-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 7.625rem;
  height: 3.125rem;
  margin: 0 auto;
  border-radius: 0.625rem;
  border: 2px solid #dadada;

  color: #838383;
  text-align: center;
  font-family: "Pretendard Variable";
  font-weight: 300;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 100%; /* 1.375rem */
}

.modal-box-chooseTime-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
}

.modal-box-chooseTime-btn-between {
  text-align: center;
  width: 7.625rem;
  height: 3.125rem;
  /* margin: 0 auto; */
  border-radius: 0.625rem;
  border: 2px solid #dadada;
  margin-bottom: 1.2rem;

  color: #838383;
  text-align: center;
  font-family: "Pretendard Variable";
  font-weight: 300;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 4rem; /* 1.375rem */
}

.modal-box-chooseTime-btn.column {
  /* height: 100%; */
  width: 100%;
  height: 4rem;
  margin-bottom: 1.2rem;
}

.modal-box-chooseTime-btn-between.row {
  /* height: 100%; */
  width: 45%;
  height: 4rem;
}

.modal-box-chooseTime-btn:active {
  background-color: #d82356;
  border: 2px solid #d82356;
  color: #fff;
}

.modal-box-chooseTime-btn-between:active {
  background-color: #d82356;
  border: 2px solid #d82356;
  color: #fff;
}

.noMenuImgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.noMenuImg {
  width: 5.5rem;
  height: 5.5rem;
}
