.loginControl-wrapper-css {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.business-status {
  color: var(--title, #2e2d2d);
  text-align: right;
  font-family: "Pretendard Variable";
  font-size: 0.75rem;
  font-weight: 700;
}
.loginControl-button-wrapper-css {
  display: flex;
  position: relative;
  border-radius: 2rem;
  min-width: 3rem;
  min-height: 1.5rem;
  margin-left: 0.5rem;
  background-color: rgba(216, 35, 86, 1);
}
.loginControl-button-wrapper-css.open {
  background-color: rgba(131, 131, 131, 1);
}

.loginControl-button-isopen-css,
.loginControl-button-isclose-css {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  width: 1.3rem;
  height: 1.3rem;
  transition: transform 0.4s ease;
  top: 0.1rem;
}

.loginControl-button-isopen-css {
  left: 0%;
  transform: translateX(10%);
}

.loginControl-button-isclose-css {
  right: 0%;
  transform: translateX(-10%);
}

@media screen and (min-width: 768px) {
}
@media screen and (min-width: 481px) and (max-width: 768px) {
}
@media screen and (max-width: 480px) {
  .loginControl-button-wrapper-css {
    margin-right: 1.5rem;
  }
}
