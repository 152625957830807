/* 모달 */
.inven-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
.inven-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.3);
  width: 50%;
  height: 50%;
  background-color: #fff;
  border-radius: 2.8125rem;
  z-index: 101;
  /* padding: 2rem; */
}
.inven-modal-close__wrapper {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.inven-modal-close__wrapper img {
  width: 1.5rem;
  height: 1.5rem;
}
.inven-modal-box-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.inven-modal-box-img__wrapper img {
  width: 4.498rem;
  height: 4.498rem;
}
.inven-modal-box-txt__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inven-modal-box-txt {
  font-family: "Pretendard Variable";
  text-align: center;
  color: #000;
  font-weight: 600;
  white-space: nowrap;
  line-height: 130%;
}
.inven-modal-box-close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #d82356;
  font-family: "Pretendard Variable";
  border-radius: 0.5rem;
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  /* 아래는 확인하면서 */
  .inven-modal-box {
    width: 34.4rem;
    height: 24.3rem;
  }
  .inven-modal-close__wrapper {
    margin-top: 3.12rem;
    margin-right: 3.25rem;
  }
  .inven-modal-box-img__wrapper {
    margin-bottom: 1.12rem;
  }
  .inven-modal-box-txt__wrapper {
    width: 24.93rem;
    height: 3.875rem;
  }
  .inven-modal-box-txt {
    font-size: 1.5rem;
  }
  .inven-modal-box-close-btn {
    font-size: 1.875rem;
    margin-top: 4.06rem;
    width: 19.4rem;
    height: 3.13rem;
  }
}
/* w:768, h:1024일 때 */
@media screen and (min-width: 480px) and (max-width: 768px) {
  /* 밑에는 수정해야함 */
  .inven-modal-box {
    width: 31.5rem;
    height: 24.3rem;
  }
  .inven-modal-close__wrapper {
    margin-top: 3.12rem;
    margin-right: 4.56rem;
  }
  .inven-modal-box-img__wrapper {
    margin-bottom: 1.44rem;
  }
  .inven-modal-box-txt__wrapper {
    width: 20.25rem;
    height: 3.25rem;
  }
  .inven-modal-box-txt {
    font-size: 1.25rem;
  }
  .inven-modal-box-close-btn {
    font-size: 1.5rem;
    margin-top: 4.31rem;
    width: 19.375rem;
    height: 3.125rem;
  }
}
/* w:480, h:800일 때 */
@media screen and (max-width: 480px) {
  /* 수정해야할 부분 */
  .inven-modal-box {
    width: 20.3rem;
    height: 17.5rem;
  }
  .inven-modal-close__wrapper {
    margin-top: 1.56rem;
    margin-right: 2.19rem;
  }
  .inven-modal-close__wrapper img {
    width: 1.25rem;
    height: 1.25rem;
  }
  .inven-modal-box-img__wrapper {
    margin-bottom: 0.75rem;
  }
  .inven-modal-box-img__wrapper img {
    margin-top: 0.81rem;
    width: 2.499rem;
    height: 2.499rem;
  }
  .inven-modal-box-txt__wrapper {
    width: 13.875rem;
    height: 3rem;
  }
  .inven-modal-box-txt {
    font-size: 1.125rem;
    min-height: 1.125rem;
  }
  .inven-modal-box-close-btn {
    font-size: 1.125rem;
    margin-top: 2.94rem;
    width: 11.4375rem;
    height: 1.875rem;
  }
}
