/* UserInputName */
.signup-page-content-name-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.signup-page-content-name-label-style {
  color: #2e2d2d;
  font-family: "Pretendard Variable";
  font-weight: 600;
}

.signup-page-content-name-input {
  margin-top: 0.3rem; /* 0.6 -0.3 = 0.3 */
  border-radius: 0.3125rem;
  border: 0.05rem solid rgba(131, 131, 131, 1);
  margin-bottom: 0.35rem;
  padding: 0.8rem 1rem 0.7rem 1.2rem;
  font-family: "Pretendard Variable";
}
/* w:768, h:1024 이상일 때 */
@media screen and (min-width: 481px) {
  .signup-page-content-name-wrapper {
    margin-top: 2rem;
    margin-bottom: 6.88rem;
    /* 8.13-0.9-0.35 */
    width: 27.9375rem;
  }
  .signup-page-content-name-label-style {
    font-size: 1.25rem;
  }
  .signup-page-content-name-input {
    font-size: 1rem;
    width: 27.9375rem;
    height: 3.125rem;
  }
}

/* w:480, h:800일 때 */
@media screen and (max-width: 480px) {
  .signup-page-content-name-wrapper {
    width: 18.75rem;
    margin-top: 1.87rem;
    margin-bottom: 3.63rem;
    /* 4.88-0.9-0.35 */
  }
  .signup-page-content-name-label-style {
    font-size: 1rem;
  }
  .signup-page-content-name-input {
    font-size: 0.75rem;
    width: 18.75rem;
    height: 2rem;
  }
}
