.judge-results-reject-page-content-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Pretendard Variable";
  white-space: nowrap;
}
.judge-results-reject-page-top-positon-icon-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
}
.judge-results-reject-page-top-positon-icon-line {
  width: 100%;
  position: absolute;
  top: 0rem;
  left: 0;
  background-color: #f4f7ff;
}
.judge-results-reject-page-content-text-style {
  font-weight: 800;
  color: var(--title, #2e2d2d);
  text-align: center;
}
.judge-results-reject-page-content-main-text-style {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--title, #2e2d2d);
  text-align: center;
  font-weight: 500;
}
/* w:768, h:1024 이상일 때 */
@media screen and (min-width: 480px) {
  .judge-results-reject-page-top-positon-icon {
    width: 5.5rem;
    height: 5.5rem;
    margin-top: -2.75rem;
  }
  .judge-results-reject-page-content-text-style {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .judge-results-reject-page-content-main-text-style {
    font-size: 1rem;
  }
  .judge-results-reject-page-next-button {
    margin-top: 7.25rem;
  }
  .judge-results-reject-page-top-positon-icon-wrapper {
    top: 2.75rem;
    height: 5.5rem;
  }
  .judge-results-reject-page-top-positon-icon-line {
    height: 2.75rem;
  }
}
/* w:480, h:800일 때 */
@media screen and (max-width: 480px) {
  .judge-results-reject-page-top-positon-icon {
    width: 3.75rem;
    height: 3.75rem;
    margin-top: -1.88rem;
  }
  .judge-results-reject-page-content-text-style {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .judge-results-reject-page-content-main-text-style {
    font-size: 1rem;
  }
  .judge-results-reject-page-next-button {
    margin-top: 6.06rem;
  }
  .judge-results-reject-page-top-positon-icon-wrapper {
    top: 1.88rem;
    height: 3.75rem;
  }
  .judge-results-reject-page-top-positon-icon-line {
    height: 1.88rem;
  }
}
