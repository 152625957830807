.sales {
  display: flex;
  flex-direction: column;
}

.sales__box1 {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.sales__amount {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 0.3125rem;
  background: #fff;
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.25);
  padding-left: 2.19rem;
  margin-top: 3.75rem;
}

.sales__amount__title {
  color: #d82356;
  font-family: "Pretendard Variable";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sales__amount__price {
  color: var(--title, #2e2d2d);
  font-family: "Pretendard Variable";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sales__standard {
  color: #4f4f4f;
  font-family: "Pretendard Variable";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0.5rem;
}

.sales__box2 {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.sales__graph {
  display: flex;
  flex-direction: column;
  border-radius: 0.3125rem;
  background: #fff;
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.25);
  align-items: center;
  padding: 2rem;
}

.sales__graph__title {
  color: var(--title, #2e2d2d);
  font-family: "Pretendard Variable";
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.sales__graph__date {
  display: flex;
  color: var(--title, #2e2d2d);
  font-family: "Pretendard Variable";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-items: center;
  justify-content: center;
}

.sales__graph__date__prev {
  width: 1.50463rem;
  height: 1.50463rem;
  margin-right: 0.5rem;
}

.sales__graph__date__next {
  width: 1.50463rem;
  height: 1.50463rem;
  margin-left: 0.5rem;
}

.sales__notice {
  color: var(--title, #2e2d2d);
  font-family: "Pretendard Variable";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.5rem;
}

@media (max-width: 480px) {
  .sales__amount {
    width: 27rem;
    height: 11.0625rem;
    padding-left: 1.5rem;
  }

  .sales__amount__price {
    font-size: 1.25rem;
  }

  .sales__standard {
    font-size: 0.625rem;
  }

  .sales__graph {
    width: 27rem;
    height: 23.25rem;
  }

  .sales__graph__title {
    font-size: 1rem;
  }

  .sales__graph__date {
    font-size: 0.75rem;
  }

  .sales__graph__date__prev {
    width: 1.25rem;
    height: 1.25rem;
  }

  .sales__graph__date__next {
    width: 1.25rem;
    height: 1.25rem;
  }

  .sales__notice {
    font-size: 0.625rem;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .sales__amount {
    width: 32.5rem;
    height: 13rem;
  }

  .sales__graph {
    width: 32.5rem;
    height: 25.1875rem;
  }

  .sales__graph__title {
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) {
  .sales__amount {
    width: 43.25rem;
    height: 13rem;
  }

  .sales__graph {
    width: 43.125rem;
    height: 28rem;
    padding: 2.41rem;
  }

  .sales__graph__title {
    font-size: 1.20369rem;
  }

  .sales__notice {
    font-weight: 500;
  }
}
