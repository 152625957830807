/* 재고관리 페이지 */
.mainInven-wrapper {
  width: 100%;
  font-family: "Pretendard Variable";
  white-space: nowrap;
  position: relative;
}

.mainInven-title__span2__category-wrapper {
  position: absolute;
  left: 15%;
  width: 25%;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 0.625rem 0.625rem;
  background-color: #838383;
}
.mainInven-category__modal {
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  cursor: pointer;
}

.mainInven-category__modal::-webkit-scrollbar {
  display: none;
}

.mainInven-category__modal {
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
.mainInven-category__modal span {
  color: #fff;
  text-align: center;
  font-family: "Pretendard Variable";
  font-weight: 600;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* 이게 밑줄 */
.mainInven-category__line {
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  height: 0.075rem;
}

@media screen and (min-width: 768px) {
  /* 확인하여 처리한 부분 */
  .mainInven-wrapper {
    width: 43.1rem;
  }
  .mainInven-title__span2__category-wrapper {
    padding-top: 0.31rem;
    /* 2.94-0.31 */
    top: 2.63rem;
  }
  .mainInven-category__modal {
    max-height: 38.4rem;
  }
  /* 아래는 확인하면서 */
  .mainInven-category__modal span {
    font-size: 1.125rem;
    /* 3.12 -0.075 =  */
    height: 3.045rem;
    line-height: 1.4625rem;
  }
  .mainInven-category-content__box span {
    font-size: 1rem;
  }
  .mainInven-category-content__box {
    width: 100%;
    /* 사이즈 조정 */
    height: 3.13rem;
  }
  .mainInven-category-checkbox {
    height: 1.4rem;
    width: 1.4rem;
  }
}
/* w:768, h:1024일 때 */
@media screen and (min-width: 480px) and (max-width: 768px) {
  /* 확인하여 처리한 부분 */
  .mainInven-wrapper {
    width: 32.36032rem;
  }
  .mainInven-category__modal {
    /* 28.6+0.23 */
    max-height: 30.9rem;
  }
  .mainInven-title__span2__category-wrapper {
    padding-top: 0.23rem;
    /* 2.17-0.23 */
    top: 1.94rem;
  }
  .mainInven-category__modal span {
    font-size: 1rem;
    /* 2.36- 0.075 */
    height: 2.285rem;
    line-height: 1.15rem;
  }
  .mainInven-category-content__box {
    width: 100%;
    /* 사이즈 조정 */
    height: 2.36rem;
  }
}
/* w:480, h:800일 때 */
@media screen and (max-width: 480px) {
  /* 처리한 부분 */
  /* 모바일의 경우 %로 */
  .mainInven-wrapper {
    width: 100%;
    min-width: 27rem;
  }
  .mainInven-category__modal {
    max-height: 28.6rem;
  }
  .mainInven-title__span2__category-wrapper {
    padding-top: 0.23rem;
    /* 2.19-0.23 */
    top: 1.96rem;
  }
  .mainInven-category__modal span {
    font-size: 1rem;
    height: 2.31rem;
    line-height: 1.15rem;
  }
  .mainInven-category__modal span {
    font-size: 1rem;
    /* 2.38- 0.075 */
    height: 2.305rem;
    line-height: 1.15rem;
  }
  .mainInven-category-content__box {
    width: 100%;
    /* 사이즈 조정 */
    height: 2.38rem;
  }
}
