/* UserInputPassword */
.signup-page-content-password-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.signup-page-content-password-label-style {
  color: #2e2d2d;
  font-family: "Pretendard Variable";
  font-weight: 600;
}

.signup-page-content-password-label-plus-text {
  display: flex;
  color: #838383;
  font-weight: 500;
  align-items: center;
  font-size: 0.75rem;
}

.signup-page-content-password-input {
  margin-top: 0.3rem; /* 0.6 -0.3 = 0.3 */
  border-radius: 0.25rem;
  border: 0.05rem solid rgba(131, 131, 131, 1);
  margin-bottom: 0.35rem;
  padding: 0.8rem 1rem 0.7rem 1.2rem;
  font-family: "Pretendard Variable";
}

/* w:768, h:1024 이상일 때 */
@media screen and (min-width: 480px) {
  .signup-page-content-password-wrapper {
    width: 28rem;
  }
  .signup-page-content-password-label-style {
    font-size: 1.25rem;
  }
  .signup-page-content-password-input {
    font-size: 1rem;
    width: 28rem;
    height: 3.125rem;
  }
}

/* w:480, h:800일 때 */
@media screen and (max-width: 480px) {
  .signup-page-content-password-wrapper {
    width: 18.75rem;
  }
  .signup-page-content-password-label-style {
    font-size: 1rem;
  }
  .signup-page-content-password-input {
    font-size: 0.75rem;
    width: 18.75rem;
    height: 2rem;
  }
}
