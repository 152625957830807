.loginpage-find-password-check-phone-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Pretendard Variable";
  margin-bottom: 1.56rem;

  /* title과 number에 공용 */
  font-size: 1rem;
  font-weight: 600;
}
.loginpage-find-password-check-phone-title {
  color: var(--title, #2e2d2d);
}
.loginpage-find-password-check-phone-number {
  color: #d82356;
  margin: 0.19rem;
}
.loginpage-find-password-check-phone-text {
  color: var(--frame-text, #838383);
  font-size: 0.625rem;
  font-weight: 500;
}
.loginpage-find-password-check-name{
    border-radius: 0.3125rem;
    border: 0.05rem solid rgba(131, 131, 131, 1);
    font-size: 1rem;
    font-weight: 500;
    padding-left: 1.25rem;
    margin-bottom: 0.31rem;
}
@media screen and (min-width: 480px) {
    .loginpage-find-password-check-name{
        width: 21.25rem;
        height: 3.125rem;
        margin-bottom: 0.31rem;
    }
  }
  
  /* w:480, h:800일 때 */
  @media screen and (max-width: 480px) {
    .loginpage-find-password-check-name{
        width: 18.75rem;
        height: 3.125rem;
        margin-bottom: 0.5rem;
    }
  }
