.password-changepage-qualification-text {
  display: flex;
  font-family: "Pretendard Variable";
  font-size: 0.75rem;
  font-weight: 500;
  color: rgba(131, 131, 131, 1);
}

.password-changepage-form {
  line-height: 1.2rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.password-changepage-password-input {
  border-radius: 0.3125rem;
  border: 0.05rem solid rgba(131, 131, 131, 1);
  padding: 0.7rem 1rem 0.7rem 0.9rem;
  font-family: "Pretendard Variable";
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.password-changepage-submit-wrapper {
  position: absolute;
  transform: translateX(0%);
}

/* w:768, h:1024 이상일 때 */
@media screen and (min-width: 480px) {
  .password-changepage-qualification-text {
    margin-bottom: 0.25rem;
  }
  .password-changepage-password-input {
    width: 21.25rem;
    height: 3.125rem;
    padding: 0.7rem 1rem 0.7rem 0.9rem;
  }
  .password-changepage-submit-wrapper {
    top: 11.19rem;
  }
}

/* w:480, h:800일 때 */
@media screen and (max-width: 480px) {
  .password-changepage-qualification-text {
    margin-bottom: 0.5rem;
  }
  .password-changepage-password-input {
    width: 18.75rem;
    height: 3.125rem;
    padding: 0.7rem 1rem 0.7rem 0.9rem;
  }
  .password-changepage-submit-wrapper {
    top: 13.12rem;
  }
}
