.user-find-id-page-frame {
  width: 100%;
  max-width: 21.3rem;
  font-family: "Pretendard Variable";
}

.loginpage-findId-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.user-find-id-page-text-output {
  border-radius: 0.3125rem;
  border: 0.05rem solid rgba(131, 131, 131, 1);
  font-size: 1rem;
  padding: 1.4rem 1rem 1.4rem 1rem;
  background-color: #ffeaef;
}

.user-find-id-page-wrapper a {
  text-decoration: none;
  color: rgba(131, 131, 131, 1);
}

.user-find-id-page-login-button {
  display: flex;
  justify-content: center;
  width: 100%;
  border: none;
  background-color: white;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: center;
  color: rgba(131, 131, 131, 1);
}

/* w:768, h:1024 이상일 때 */
@media screen and (min-width: 481px) {
  .user-find-id-page-text-output {
    width: 21.25rem;
    height: 4.75rem;
    margin-top: 3.81rem;
    margin-bottom: 4.56rem;
  }
  .loginpage-findId-text span {
    font-size: 1rem;
  }
  .user-find-id-page-login-button {
    margin-top: 1.62rem;
  }
}

/* w:480, h:800일 때 */
@media screen and (max-width: 480px) {
  .user-find-id-page-text-output {
    width: 18.8rem;
    height: 4.38rem;
    margin-top: 4rem;
    margin-bottom: 3.25rem;
  }
  .loginpage-findId-text span {
    font-size: 0.75rem;
  }
  .user-find-id-page-login-button {
    margin-top: 1.06rem;
  }
}
