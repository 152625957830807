* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 125%; */
  -webkit-text-size-adjust: none;
}

@media screen and (min-device-width: 0px) and (max-device-width: 479.9px) {
  html {
    font-size: 75%;
  }
}

@media screen and (min-device-width: 480px) {
  html {
    font-size: 75%;
  }
}

@media screen and (min-device-width: 768px) {
  html {
    font-size: 100%;
  }
}

@media screen and (min-device-width: 1280px) {
  html {
    font-size: 125%;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
