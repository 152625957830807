/* 안의 내용 */
.circle-num-ber-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* 원 */
.circle-num-wapper {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Pretendard Variable";
  font-weight: 800;
}
/* 원 색상 */
.circle-num-wapper-point {
  border: 0.2rem solid rgba(79, 79, 79, 1);
  color: rgba(79, 79, 79, 1);
}
.circle-num-wapper-not-point {
  border: 0.2rem solid rgba(217, 217, 217, 1);
  color: rgba(217, 217, 217, 1);
}

/* 바 */
.bar-wapper {
  height: 0.2rem;
  border-radius: 0.1rem;
}
/* 바 색상 */
.bar-wapper-point {
  background-color: rgba(79, 79, 79, 1);
}
.bar-wapper-not-point {
  background-color: rgba(217, 217, 217, 1);
}

/* 글자 */
.circle-num-ber-text-wrapper {
  position: absolute;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.circle-num-bar-text-content {
  color: rgba(217, 217, 217, 1);
  text-align: center;
  font-family: "Pretendard Variable";
  font-weight: 800;
}
/* 글자 색 */
.circle-num-bar-text-point {
  color: rgba(79, 79, 79, 1);
}
.circle-num-bar-text-not-point {
  color: rgba(217, 217, 217, 1);
}

/* w:768, h:1024 이상일 때 */
@media screen and (min-width: 480px) {
  .circle-num-wapper {
    width: 2.45rem;
    height: 2.45rem;
    font-size: 1.54344rem;
  }
  .bar-wapper {
    width: 8.08456rem;
    margin: 0 0.41rem;
  }
  .circle-num-bar-text-content {
    font-size: 1rem;
  }
  .circle-num-ber-text-wrapper {
    bottom: -1.35rem;
    transform: translateX(-52%);
  }
}

/* w:480, h:800일 때 */
@media screen and (max-width: 480px) {
  .circle-num-wapper {
    width: 1.44rem;
    height: 1.44rem;
    font-size: 0.75rem;
  }
  .bar-wapper {
    width: 4.7445rem;
    margin: 0 0.2rem;
    font-size: 0.90131rem;
  }
  .circle-num-bar-text-content {
    font-size: 0.75rem;
  }
  .circle-num-ber-text-wrapper {
    bottom: -1.16rem;
    transform: translateX(-51%);
  }
}
