.check-message {
  display: flex;
  align-items: center;
  color: #d82356;
  font-family: "Pretendard Variable";
  font-size: 0.75rem;
  font-weight: 500;
}
.check-message img {
  /* width: 0.75rem; */
  height: 0.75rem;
}
