/* InputStoreInformationText */
.signup-page-content-store-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Pretendard Variable";
}
.signup-page-content-store-label-style {
  display: flex;
  color: #2e2d2d;
  font-weight: 600;
}
.signup-page-content-store-input {
  border-radius: 0.1875rem;
  border: 0.583px solid var(--frame-text, #838383);
  background: #fff;
  padding: 0.8rem 1rem 0.7rem 1.2rem;
  color: var(--frame-text, #838383);
  font-weight: 500;
}

.signup-page-content-store-input-style {
  display: flex;
  align-items: center;
  flex-direction: row;
}

/* InputStoreAddressWrapper */
/* InputStoreInformationflie */
.signup-page-content-store-input-top-wrapper {
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
}
.signup-page-content-store-input-top {
  border-radius: 0.1875rem;
  border: 0.05rem solid rgba(131, 131, 131, 1);
  padding: 0.8rem 1rem 0.7rem 1.2rem;
  font-weight: 500;
}
.signup-page-content-store-input-top-button {
  border-radius: 0.1875rem;
  border: 0.05rem solid rgba(131, 131, 131, 1);
  background-color: #fff;
  height: 100%;
  color: rgba(131, 131, 131, 1);
  font-weight: 500;
}

/* ConsentForm */
.signup-page-content-store-check {
  color: #2e2d2d;
  font-family: "Pretendard Variable";
  font-weight: 500;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.signup-page-content-store-check input[type="checkbox"] {
  accent-color: rgba(216, 35, 86, 1);
}

/* w:768, h:1024 이상일 때 */
@media screen and (min-width: 480px) {
  /* InputStoreInformationText */
  .signup-page-content-store-wrapper {
    margin-bottom: 1.5rem;
    width: 28rem;
  }
  .signup-page-content-store-label-style {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  .signup-page-content-store-input {
    width: 28rem;
    height: 3.125rem;
    font-size: 1rem;
  }
  /* InputStoreAddressWrapper */
  .signup-page-content-store-input-top-wrapper {
    width: 28rem;
  }
  .signup-page-content-store-input-top {
    margin-bottom: 0.5rem;
    margin-right: 0.69rem;
    width: 21.9375rem;
    height: 3.125rem;
    font-size: 1rem;
  }
  .signup-page-content-store-input-top-button {
    width: 5.375rem;
    height: 3.125rem;
    font-size: 1rem;
  }
  .signup-page-content-store-input-margin {
    margin-top: 0.5rem;
  }
  /* ConsentForm */
  .signup-page-content-store-check {
    width: 28rem;
    height: 3.125rem;
    font-size: 1rem;
  }
  .signup-page-content-store-check input {
    width: 1.5rem;
    height: 1.5rem;
  }
}

/* w:480, h:800일 때 */
@media screen and (max-width: 480px) {
  /* InputStoreInformationText */
  .signup-page-content-store-wrapper {
    margin-bottom: 1rem;
  }
  .signup-page-content-store-label-style {
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
  }
  .signup-page-content-store-input {
    width: 18.75rem;
    height: 2.1875rem;
    font-size: 0.625rem;
  }
  /* InputStoreAddressWrapper */
  .signup-page-content-store-input-top-wrapper {
    width: 18.75rem;
  }
  .signup-page-content-store-input-top {
    margin-bottom: 0.5rem;
    margin-right: 0.25rem;
    width: 15.375rem;
    height: 2.1875rem;
    font-size: 0.625rem;
  }
  .signup-page-content-store-input-top-button {
    width: 3.13544rem;
    height: 2.1875rem;
    font-size: 0.625rem;
  }
  .signup-page-content-store-input-margin {
    margin-top: 0.25rem;
  }
  /* ConsentForm */
  .signup-page-content-store-check {
    width: 16.33331rem;
    height: 1.82294rem;
    font-size: 0.625rem;
  }
  .signup-page-content-store-check input {
    width: 0.875rem;
    height: 0.875rem;
  }
}
