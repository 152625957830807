.loginpage-find-password-check-id-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Pretendard Variable";
}

.loginpage-find-password-check-id-wrapper input {
  border-radius: 0.3125rem;
  border: 1px solid var(--frame-text, #838383);
  background: #fff;
  padding-left: 1.06rem;

  color: var(--title, #2e2d2d);
  font-weight: 500;
  font-size: 1rem;
}
.loginpage-find-password-check-id-wrapper input::placeholder {
  color: var(--frame-text, #838383);
}

/* 480이상 */
@media screen and (min-width: 480px) {
  .loginpage-find-password-check-id-wrapper input {
    width: 21.25rem;
    height: 3.125rem;
  }

  .loginpage-find-password-next-button {
    margin-top: 10rem;
  }
}

/* w:480, h:800일 때 */
@media screen and (max-width: 480px) {
  .loginpage-find-password-check-id-wrapper input {
    width: 18.75rem;
    height: 3.125rem;
  }

  .loginpage-find-password-next-button {
    margin-top: 10rem;
  }
}
