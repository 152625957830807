.chart-container {
  width: 63.5%;
  height: 100%;
  display: grid;
  white-space: nowrap;
  grid-template-columns: 1fr 9fr;
  grid-template-rows: 1fr 9fr;
  margin-top: 3.81rem;
}

/* 첫 줄 첫칸 빈칸용 */
.grid-1 {
  grid-row: 1;
  grid-column: 1;
}
/* 요일 컬럼 */
.chart-day-column-container {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  justify-content: space-between;
}
.chart-day-column {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.chart-day-column-name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: var(--light-grey, #d9d9d9);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Pretendard Variable";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  font-variant: all-small-caps;
  width: 100%;
}

/* 차트 눈금 */
.bar-chart {
  display: flex;
  grid-column: 2;
  grid-row: 2;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.chart-sales-column-container {
  display: flex;
  align-items: center;
  position: absolute;
  left: -1.35rem;
  right: -1.37rem;
}
.chart-sales-column-line-wrapper {
  position: relative;
  align-items: center;
  display: flex;
  width: 100%;
  /* height: auto; */
}
.chart-sales-column-label {
  position: absolute;
  left: -2.15rem;
  padding-bottom: 0.2rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--light-grey, #d9d9d9);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Pretendard Variable";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-variant: all-small-caps;
}
.chart-sales-column-line {
  width: 100%;
  border-top: 1px solid #d9d9d9;
}

/* 차트 그래프 */
.bar-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar-wrapper {
  height: 100%;
  width: 80%;
  width: 1.73031rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.bar-label {
  position: relative;
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Pretendard Variable";
  font-size: 1rem;
  font-weight: 900;
  font-variant: all-small-caps;
}
.bar {
  border-radius: 0.37619rem 0.37619rem 0rem 0rem;
  background: #d82356;
  width: 100%; /* 막대 너비를 부모와 동일하게 설정 */
  transition: height 0.1s ease-in-out; /* 높이 변경시 애니메이션 */
}
