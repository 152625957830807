.user-input-number-message-wrapper {
  display: flex;
  align-items: center;
  font-family: "Pretendard Variable";
  border: 0.05rem solid rgba(131, 131, 131, 1);
  border-radius: 0.3125rem;

  margin-top: 0.31rem;
  margin-bottom: 0.31rem;
  width: 100%;
  height: 3.125rem;
  padding-left: 1.06rem;
}

.user-input-number-message-wrapper:focus-within {
  outline: 1px solid var(--title, #2e2d2d);
}
.user-input-number-message-wrapper input {
  border: none;
  font-size: 1rem;
}
.user-input-number-message-wrapper input:focus {
  border: none;
  outline: none;
}
.timer {
  display: flex;
  margin-right: 1.28rem;
  margin-left: auto;
  font-family: "Pretendard Variable";
  font-size: 1rem;
  color: #838383;
}
