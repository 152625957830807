.home-wrapper {
  display: flex;
  position: absolute;
  top: 6.5rem;
  width: 100vw;
  height: calc(100vh - 6.5rem);
  /* height: 100vh; */
}

.Main-Box {
  width: 100%;
  /* min-width: 33rem; */
  /* height: 100%; */
  padding: 3vh 0;
}

@media screen and (max-width: 480px) {
  .Main-Box{height: 100%;}
}

.status-header {
  border-bottom: 8px solid #d82356;
  padding-top: 1.625rem;
}

.main-header__wrapper {
  display: flex;
  width: 95%;
  margin: 0 auto;
}

.main-header-btn__wrapper {
  width: 33%;
}

.main-header-btn {
  width: 100%;
  border-radius: 0.875rem 0.875rem 0 0;
  background-color: #ffffff;
  font-family: "SemiBold";
  color: #838383;
  font-size: 1.375rem;
  border: none;
  padding: 0.5rem 0;
  white-space: nowrap;
}

.main-header-btn.selected {
  background-color: #d82356;
  font-family: "ExtraBold";
  color: #fff;
}

.recipt_box{
  width: 50%;
  height: 100%;
}