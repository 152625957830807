.mainInven-category-content__box {
  display: flex;
  width: 100%;
  /* height: 1.9rem; */
  height: 3.17188rem;
  border-bottom: #dadada solid 0.075rem;
}

.mainInven-category-content__box.selected {
  background-color: rgba(216, 35, 86, 0.15);
}

.mainInven-category-content__box span {
  font-family: "Pretendard Variable";
  height: 3.17188rem;
  color: #4f4f4f;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 115%;
  height: 100%;
}

.mainInven-category-checkbox {
  border: #dadada solid 0.1875rem;
  border-radius: 0.125rem;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
}

.mainInven-category-checkbox.selected {
  background: #d82356
    url("../../../../assets/images/ic_inventory_check_white.png") no-repeat
    center center;
  border: none;
  background-size: 70%;
}

.mainInven-category-content__span1 {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #dadada solid 0.075rem;
}

.mainInven-category-content__span2 {
  width: 25%;
  white-space: nowrap;
  border: #dadada solid 0.075rem;
  overflow-x: hidden;
}

.mainInven-category-content__span3 {
  width: 60%;
  white-space: nowrap;
  border: #dadada solid 0.075rem;
  overflow-x: hidden;
}

@media (max-width: 480px) {
  .mainInven-category-content__box span {
    font-size: 1rem;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .mainInven-category-content__box span {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .mainInven-category-content__box span {
    font-size: 1.25rem;
  }
}
