/* UserInPutId */
.signup-page-content-id-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 2rem;
}

.signup-page-content-id-label-style {
  color: #2e2d2d;
  font-family: "Pretendard Variable";
  font-weight: 600;
}

.signup-page-content-id-label-plus-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #838383;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.signup-page-content-id-input {
  margin-bottom: 0.31rem;
  border-radius: 0.3125rem;
  border: 0.05rem solid rgba(131, 131, 131, 1);
  padding: 0.8rem 1rem 0.7rem 1.2rem;
  font-family: "Pretendard Variable";
}

/* UserInputId button */
.duplication-check-button {
  border-radius: 1.5rem;
  background: #d82356;
  border: none;
  color: rgb(255, 255, 255);
  font-family: "Pretendard Variable";
  font-weight: 700;
}

.duplication-check-button-inactive {
  border-radius: 1.5rem;
  background: #d9d9d9;
  border: none;
  color: rgb(255, 255, 255);
  font-family: "Pretendard Variable";
  font-weight: 700;
}

/* w:768, h:1024 이상일 때 */
@media screen and (min-width: 480px) {
  .signup-page-content-id-label-style {
    font-size: 1.25rem;
  }

  .signup-page-content-id-label-plus-text {
    width: 28rem;
  }

  .signup-page-content-id-input {
    font-size: 1rem;
    width: 28rem;
    height: 3.125rem;
  }

  /* UserInputId button */
  .duplication-check-button {
    width: 5rem;
    height: 1.25rem;
    font-size: 0.75rem;
  }
  .signup-page-content-id-wrapper {
    margin-top: 3.5rem;
  }
}

/* w:480, h:800일 때 */
@media screen and (max-width: 480px) {
  .signup-page-content-id-wrapper {
    margin-top: 3.65rem;
  }
  .signup-page-content-id-label-style {
    font-size: 1rem;
  }

  .signup-page-content-id-label-plus-text {
    width: 18.6875rem;
  }

  .signup-page-content-id-input {
    font-size: 0.75rem;
    width: 18.75rem;
    height: 2rem;
  }

  /* UserInputId button */
  .duplication-check-button {
    width: 3.375rem;
    height: 1rem;
    font-size: 0.5rem;
  }
}
