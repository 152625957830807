.mainInven-category-content__wrapper{
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.mainInven-category-content__wrapper::-webkit-scrollbar {
    display: none;
}

.mainInven-category-content__wrapper {
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
}

@media (max-width: 480px) {
    .mainInven-category-content__wrapper{
        font-size: 1rem;
    }
    .mainInven-category-content__wrapper{
        height: 30.9rem;
    }
}
  
@media (min-width: 480px) and (max-width: 768px) {
    .mainInven-category-content__wrapper{
        font-size: 1rem;
    }
    .mainInven-category-content__wrapper{
        height: 30.9rem;
        margin-bottom: 11.44rem;
    }
}
  
@media (min-width: 768px) {
    .mainInven-category-content__wrapper{
        font-size: 1.25rem;
    }
    .mainInven-category-content__wrapper{
        height: 40.8rem;
        margin-bottom: 11.44rem;
    }
}