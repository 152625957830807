.main__promotion {
  display: flex;
}

.main__president {
  border-radius: 0.3125rem;
  border: 0.75px solid #d9d9d9;
  background: #fff;
}

.main__box2 {
  display: flex;
  flex-direction: column;
}

.main__president__ready {
  color: var(--title, #2e2d2d);
  font-family: "Pretendard Variable";
  font-size: 0.5625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.main__president__name {
  color: var(--title, #2e2d2d);
  font-family: "Pretendard Variable";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.main__box3 {
  display: flex;
  flex-direction: column;
}

.main__order-management-btn {
  width: fit-content;
  color: #fff;
  font-family: "Pretendard Variable";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0.66rem 2.21rem;
  border-radius: 0.3125rem;
  background: var(--frame-text, #d82356);
}

.main__order-management-btn.disabled {
  background: var(--frame-text, #838383);
}

.main__order-management__notice {
  color: #838383;
  font-family: "Pretendard Variable";
  font-size: 0.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.25rem;
}

.main__sales {
  display: flex;
  width: fit-content;
  flex-direction: column;
}

.main__box5 {
  display: flex;
  flex-direction: column;
}

.main__sales__amount {
  display: flex;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main__sales__amount__title {
  color: #4f4f4f;
  font-family: "Pretendard Variable";
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.main__sales__amount__content {
  color: #d82356;
  font-family: "Pretendard Variable";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.main__sales__amount__predicate {
  color: var(--title, #2e2d2d);
  font-family: "Pretendard Variable";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.main__sales__case {
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: #fff;
}

.main__sales__case__this-week {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.25rem;
  align-items: center;
}

.main__sales__case__title {
  color: #000;
  font-family: "Pretendard Variable";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* margin-right: 5.37rem; */
}

.main__sales__case__amount {
  color: #4f4f4f;
  text-align: right;
  font-family: "Pretendard Variable";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.main__sales__case__this-month {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.main__sales__standard {
  color: #838383;
  font-family: "Pretendard Variable";
  font-size: 0.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 0.25rem;
}

.main__guide {
  width: fit-content;
}

.main__guide__title {
  display: flex;
  align-items: center;
  color: var(--title, #2e2d2d);
  font-family: "Pretendard Variable";
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-decoration: none;
  margin-top: auto;
  margin-bottom: 0.69rem;
}

.main__guide__title img {
  width: 1.5rem;
  height: 1.5rem;
}

.main__guide__content {
  display: flex;
  flex-direction: row;
}

.main__guide1 {
  display: flex;
  border-radius: 0.3125rem;
  background-image: url("../../assets/images/img_guide1.png");
  background-repeat: no-repeat; /* 이미지를 반복하지 않음 */
  background-position: center; /* 이미지를 요소의 중앙에 위치 */
  background-size: cover; /* 이미지가 요소의 크기를 커버하도록 조정 */
  color: #fff;
  font-family: "Pretendard Variable";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 1.19rem;
  margin-right: 0.25rem;
}

.main__guide2 {
  display: flex;
  border-radius: 0.3125rem;
  background-image: url("../../assets/images/img_guide2.png");
  background-repeat: no-repeat; /* 이미지를 반복하지 않음 */
  background-position: center; /* 이미지를 요소의 중앙에 위치 */
  background-size: cover; /* 이미지가 요소의 크기를 커버하도록 조정 */
  color: #fff;
  font-family: "Pretendard Variable";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 1.19rem;
  margin-right: 0.25rem;
}

.main__guide3 {
  display: flex;
  border-radius: 0.3125rem;
  background-image: url("../../assets/images/img_guide3.png");
  background-repeat: no-repeat; /* 이미지를 반복하지 않음 */
  background-position: center; /* 이미지를 요소의 중앙에 위치 */
  background-size: cover; /* 이미지가 요소의 크기를 커버하도록 조정 */
  color: #fff;
  font-family: "Pretendard Variable";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 1.19rem;
}

.main__box6 {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.main__notice {
  width: fit-content;
}

.main__notice__title {
  display: flex;
  color: var(--title, #2e2d2d);
  font-family: "Pretendard Variable";
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  align-items: center;
  margin-top: auto;
  margin-bottom: 0.69rem;
  text-decoration: none;
}

.main__notice__title img {
  width: 1.5rem;
  height: 1.5rem;
}

ul {
  padding-left: 0; /* 왼쪽 패딩 제거 */
  list-style-position: inside; /* 점을 내부에 위치시킴 */
}

.main__notice__content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 0.23438rem;
  border: 0.75px solid var(--light-grey, #d9d9d9);
}

.main__notice__content__item {
  position: relative;
}

.main__notice__content__title {
  color: var(--title, #2e2d2d);
  text-align: right;
  font-family: "Pretendard Variable";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: auto;
}

.main__notice__content__date {
  position: absolute;
  right: 0; /* 오른쪽 정렬 */
  top: 50%; /* 상하 중앙 정렬 */
  transform: translateY(-50%); /* 상하 중앙 정렬 */
  color: var(--frame-text, #838383);
  text-align: right;
  font-family: "Pretendard Variable";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.main__notice__consultation {
  border-radius: 0.3125rem;
  border: 0.75px solid var(--light-grey, #d9d9d9);
}

.main__notice__consultation__title {
  color: var(--title, #2e2d2d);
  font-family: "Pretendard Variable";
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.main__notice__consultation__content {
  display: flex;
  flex-direction: column;
}

.main__notice__consultation__ment {
  color: var(--title, #2e2d2d);
  font-family: "Pretendard Variable";
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.main__notice__consultation__kakao {
  display: flex;
  flex-direction: row;
  font-family: "Pretendard Variable";
  font-size: 0.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transform: rotate(0.029deg);
}

.main__notice__consultation__kakao-btn {
  background: #e6e6e6;
  border-radius: 0.30163rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 480px) {
  .main__promotion {
    width: 27rem;
    height: 7.5625rem;
    margin: 1.12rem auto 1.81rem auto;
  }

  .main__box1 {
    display: flex;
    flex-direction: column;
  }

  .main__president {
    display: flex; /* Flexbox 레이아웃을 사용 */
    width: 27rem;
    height: 4.4375rem;
    justify-content: space-between; /* 아이템들을 양 끝으로 정렬 */
    align-items: center; /* 수직 중앙 정렬 */
    padding: 10px; /* 패딩을 줘서 안쪽 여백을 생성 */
    margin-left: auto;
    margin-right: auto;
  }

  .main__order-management__notice {
    margin-top: 0.12rem;
  }

  .main__sales {
    display: flex;
    width: fit-content;
    flex-direction: column;
    margin-top: 0.81rem;
    margin-left: auto;
    margin-right: auto;
  }

  .main__sales__amount__title {
    margin-bottom: 0.25rem;
  }

  .main__box5 {
    display: flex;
    flex-direction: row;
  }

  .main__sales__amount {
    display: flex;
    width: 13.125rem;
    height: 5.8125rem;
    border-radius: 0.3125rem;
    border: 0.75px solid #d9d9d9;
    background: #fff;
    flex-direction: column;
    margin-right: 0.75rem;
  }

  .main__sales__case {
    width: 13.125rem;
    height: 5.8125rem;
    border-radius: 0.3125rem;
    border: 0.75px solid #d9d9d9;
    background: #fff;
  }

  .main__sales__standard {
    color: #838383;
    font-family: "Pretendard Variable";
    font-size: 0.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0.25rem;
  }

  .main__guide {
    margin-top: 1.69rem;
    margin-left: auto;
    margin-right: auto;
  }

  .main__guide1 {
    width: 8.75rem;
    height: 8.5rem;
  }

  .main__guide2 {
    width: 8.75rem;
    height: 8.5rem;
  }

  .main__guide3 {
    width: 8.75rem;
    height: 8.5rem;
  }

  .main__box6 {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 2.31rem auto 9rem auto;
  }

  .main__notice__content {
    width: 27rem;
    height: 8.625rem;
    padding-left: 0.88rem;
    padding-right: 1.81rem;
  }

  .main__notice__consultation {
    width: 27.0625rem;
    height: 5.6875rem;
    margin-top: 1rem;
    padding: 1.44rem 1.23rem 1.15rem 0.94rem;
  }

  .main__notice__consultation__content {
    flex-direction: row;
    justify-content: space-between;
  }

  .main__notice__consultation__kakao {
    margin-bottom: 0.37rem;
  }
  .main__notice__consultation__kakao-btn {
    width: 1.35263rem;
    height: 0.90488rem;
    margin-left: 1.47rem;
  }
  .main__notice__consultation__kakao-btn img {
    /* width: 0.55969rem; */
    height: 0.47506rem;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .main__box1 {
    display: flex;
    width: fit-content;
    height: 8.5625rem;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.69rem;
  }

  .main__promotion {
    width: 19.375rem;
    margin-right: 1.5rem;
  }

  .main__president {
    display: flex;
    width: 11.625rem;
    height: 8.5625rem;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .main__box4 {
    display: flex;
    flex-direction: row-reverse;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .main__box5 {
    border-radius: 0.3125rem;
    border: 0.75px solid #d9d9d9;
    background: #fff;
  }

  .main__sales {
    margin-top: 0.81rem;
  }

  .main__sales__amount {
    width: 11.625rem;
    height: 5.75rem;
    border-bottom: 0.75px solid #d9d9d9;
  }

  .main__sales__case {
    width: 11.625rem;
    height: 5.25rem;
  }

  .main__guide {
    margin-top: auto;
    margin-bottom: 0.875rem;
    margin-right: 1.5rem;
  }

  .main__guide1 {
    width: 6.25rem;
    height: 8.5rem;
    font-size: 0.5625rem;
    margin-right: 0.31rem;
  }

  .main__guide2 {
    width: 6.25rem;
    height: 8.5rem;
    font-size: 0.5625rem;
    margin-right: 0.31rem;
  }

  .main__guide3 {
    width: 6.25rem;
    height: 8.5rem;
    font-size: 0.5625rem;
  }

  .main__box6 {
    margin-top: 0.88rem;
  }

  .main__notice {
    margin-right: 1.5rem;
  }

  .main__notice__content {
    width: 19.375rem;
    height: 8.625rem;
  }

  .main__notice__content__item {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .main__notice__content__date {
    font-size: 0.5rem;
  }

  .main__notice__consultation {
    display: flex;
    flex-direction: column;
    width: 11.625rem;
    height: 8.6875rem;
    margin-top: auto;
    padding-top: 1.52rem;
    padding-left: 0.91rem;
    padding-bottom: 1.23rem;
  }

  .main__notice__consultation__content {
    flex-grow: 1;
  }

  .main__notice__consultation__ment {
    display: flex;
    flex-direction: column;
    margin-top: 0.3rem;
  }

  .main__notice__consultation__kakao {
    margin-top: auto;
  }
  .main__notice__consultation__kakao-btn {
    width: 1.35263rem;
    height: 0.90488rem;
    margin-left: 1.47rem;
  }
  .main__notice__consultation__kakao-btn img {
    /* width: 0.55969rem; */
    height: 0.47506rem;
  }
}

@media (min-width: 769px) {
  .main__box1 {
    display: flex;
    width: fit-content;
    height: 11.4375rem;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.25rem;
  }

  .main__promotion {
    width: 26rem;

    margin-right: 1.5rem;
  }

  .main__president {
    display: flex;
    width: 15.625rem;
    height: 11.4375rem;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .main__box4 {
    display: flex;
    flex-direction: row-reverse;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .main__sales {
    margin-top: 1.31rem;
  }

  .main__box5 {
    border-radius: 0.3125rem;
    border: 1px solid #d9d9d9;
    background: #fff;
  }

  .main__sales__amount {
    width: 15.625rem;
    height: 7.69rem;
    border-bottom: 0.75px solid #d9d9d9;
    padding-left: 1.37rem;
    padding-right: 1.37rem;
  }

  .main__sales__amount__title {
    font-size: 0.75rem;
    margin-bottom: 0.31rem;
  }

  .main__sales__amount__content {
    font-size: 1.25rem;
  }

  .main__sales__amount__predicate {
    font-size: 1.25rem;
  }

  .main__sales__case {
    width: 15.625rem;
    height: 6.44rem;
  }

  .main__sales__case__title {
    font-size: 1rem;
  }

  .main__sales__case__amount {
    font-size: 1.25rem;
  }

  .main__guide {
    margin-top: auto;
    margin-bottom: 0.875rem;
    margin-right: 1.5rem;
  }

  .main__guide__title {
    font-size: 1.25rem;
  }

  .main__guide1 {
    width: 8.4375rem;
    height: 11.4375rem;
  }

  .main__guide2 {
    width: 8.4375rem;
    height: 11.4375rem;
  }

  .main__guide3 {
    width: 8.4375rem;
    height: 11.4375rem;
  }

  .main__box6 {
    margin-top: 1.44rem;
  }

  .main__notice {
    margin-right: 1.5rem;
  }

  .main__notice__content {
    width: 26rem;
    height: 12.75rem;
  }

  .main__notice__content__item {
    margin-left: 0.81rem;
    margin-right: 0.75rem;
  }

  .main__notice__title {
    font-size: 1.25rem;
  }

  .main__notice__consultation {
    display: flex;
    flex-direction: column;
    width: 15.625rem;
    height: 12.75rem;
    border: 1px solid var(--light-grey, #d9d9d9);
    padding-top: 2.25rem;
    padding-left: 1.63rem;
    padding-bottom: 2.53em;
    margin-top: auto;
  }

  .main__notice__consultation__title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .main__notice__consultation__content {
    flex-grow: 1;
  }

  .main__notice__consultation__ment {
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }

  .main__notice__consultation__kakao {
    font-size: 0.5rem;
    margin-top: auto;
  }
  .main__notice__consultation__kakao-btn {
    width: 1.8035rem;
    height: 1.2065rem;
    margin-left: 1.95rem;
  }
  .main__notice__consultation__kakao-btn img {
    width: 0.74625rem;
    height: 0.63344rem;
  }
}
